import request from '@/utils/request'
import Api from '@/api/base/api-config'

//获取通用状态basedata
export const getBaseaData = () => {
  return request({
    method: 'get',
    url: Api.general.basedatas
  })
}
// 获取全部门店列表 ST0001是获取全部的
export const storeList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.storeList}?orgCode=${params.code}`
  })
}
// 创建排程时获取试驾车列表
export const vehicleList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.carList}?storeCode=${params.storeCode}&page=${params.page}&size=${params.size}`
  })
}
// 创建时获取试驾活动列表
export const activetyList = () => {
  return request({
    method: 'get',
    url: `${Api.testdrive.activetyList}?page=1&size=99999`
  })
}
// 创建时获取陪同人员列表
export const poiltList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.poiltList}?storeCode=${params.storeCode}`
  })
}
// 创建时获取陪同人员列表
export const userList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.account}?phone=${params.phone}`
  })
}
// 根据选择的门店时间车辆获取当前车辆可以选择的时间 也就是获取排程列表的接口
export const checkTime = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.checkTime}?storeCode=${params.storeCode}&startDate=${params.startDate}&endDate=${params.endDate}&vehicleId=${params.vehicleId}`
  })
}
// 创建试驾排程
export const createTestdrive = (params) => {
  return request({
    method: 'post',
    url: Api.testdrive.createTestDrive,
    data: params
  })
}
// 根据手机号搜索排程
export const searchTestdrive = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.searchTestdrive}?accountPhone=${params.accountPhone}&page=${params.page}&size=${params.size}`
  })
}
// 获取试驾详情
export const testdriveDetail = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.createTestDrive}${params}`
  })
}
// 修改试驾排程
export const revapmTestdrive = (params) => {
  return request({
    method: 'post',
    url: `${Api.testdrive.createTestDrive}${params}`
  })
}
// 获取试驾操作记录
export const logList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.logList}?&page=${params.page}&size=${params.size}&testDriveId=${params.testDriveId}`
  })
}
// 修改排程状态
export const changeStatus = (id, params) => {
  return request({
    method: 'post',
    url: `${Api.testdrive.changeStatus}/${id}`,
    data: params
  })
}
// 获取待分配poilt列表
export const unPoiltList = (params) => {
  return request({
    method: 'get',
    url: `${Api.testdrive.unPoilt}?storeCode=${params.code}&page=${params.page}&size=${params.size}`
  })
}
// 分配poilt人员
export const changePoilt = (params) => {
  return request({
    method: 'post',
    url: `${Api.testdrive.changePoilt}/${params.id}?coPilot=${params.poilt}`
  })
}
// 上传试驾协议
export const testdriveUpload = (formData) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: 'testdriveUpload',
    data: formData
  })
}
// 修改试驾排程
export const changeTestdriveDetail = (id, params) => {
  return request({
    method: 'post',
    url: `${Api.testdrive.createTestDrive}${id}`,
    data: params
  })
}