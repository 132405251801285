<template>
  <App
    title="分配Co-Pilot"
    :border="false"
  >
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />
    <FooterBtn
      @cancel="chinal"
      @ok="onClickRight"
    ></FooterBtn>
  </App>
</template>
<script>
import { Toast } from 'vant'
import DynamicForm from '_c/dynamicForm'
import FooterBtn from '_c/footerBtn/index'
import { poiltList, changePoilt } from '@/api/testdrive/index'
export default {
  name: 'coPilotAllot',
  components: {
    DynamicForm,
    FooterBtn
  },
  data () {
    return {
      id: '',
      options: [],
      model: {
        coPilot: ''
      },
      rules: {
        coPilot: [
          { required: true, message: '请选择poilt' }
        ]
      },
      fieldData: [
        {
          data: [
            { label: 'Co-Pilot', key: 'coPilot', type: 'select', options: () => this.options }
          ]
        }
      ]
    }
  },
  methods: {
    // 获取页面参数
    getQuery () {
      this.id = this.$route.query.id
      this.code = this.$route.query.code
    },
    // 根据门店获取poilt人员列表
    async getPoilt () {
      const res = await poiltList({
        storeCode: this.code// 门店code
      })
      if (res.success) {
        res.data.forEach(item => {
          this.options.push({
            'label': item.name,
            'value': item.idmUserid
          })
        })
      }
    },
    // 确定按钮点击
    onClickRight () {
      this.$refs.form.validate().then(async () => {
        const res = await changePoilt({
          'id': this.id,
          'poilt': this.model.coPilot
        })
        if (res.success) {
          Toast('分配成功')
          this.$router.go(-1)
        }
      })
    },
    async init () {
      await this.getQuery()
      this.getPoilt()
    },
    chinal () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
</style>